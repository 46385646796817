<template>
  <div class="index" >
    <van-cell-group class="index-contract-detail" @click="toContract">
      <van-cell class="invoice-detail">
        <van-row>
          <van-col span="16" class="index-title">合同管理</van-col>
        </van-row>
      </van-cell>
      <van-cell>
        <van-col span="24" class="invoice-desc">
          <van-row>
            <van-col :span="10" class="invoice-desc">
              <span class="font-contract-color">生效合同</span>
            </van-col>
            <van-col :span="14" class="invoice-desc">
              <span class="font-contract-color">合同金额</span>
            </van-col>
          </van-row>
        </van-col>
        <van-col span="24">
          <van-row>
            <van-col :span="10" class="invoice-contract-desc">
              {{contract_number}}
            </van-col>
            <van-col :span="14" class="invoice-contract-desc" style="margin-left: -10px">
              ￥{{total_contract_amount}}
<!--              ￥9999999.00-->
            </van-col>
          </van-row>
        </van-col>
      </van-cell>
      <van-row style="margin-top: 0.2rem">
        <van-col span="1"></van-col>
        <van-col span="22" v-if="to_pay_data.length > 0 && to_over_time.length==0 && to_pay_data[0].is_show==2 && to_pay_data[0].is_end_pay==1" style="height: 32px;font-size: 13px;line-height: 32px;background-color:#FAFAFA;margin-bottom: 10px;text-align: left;border-radius: 2px;">
          <span class="notice-font-color">{{to_pay_data[0].cycle_time}}前需支付下期费用：{{to_pay_data[0].cycle_total_amount}}。</span>
        </van-col>
        <van-col span="22" v-if="to_over_time.length > 0" style="height: 64px;font-size: 13px;line-height: 32px;background-color:#FAFAFA;margin-bottom: 10px;text-align: left;border-radius: 2px;">
          <span class="notice-font-color" v-if="to_pay_data.length > 0">{{to_pay_data[0].cycle_time}}前需支付下期费用：{{to_pay_data[0].cycle_total_amount}}</span><br>
          <span class="notice-font-color">合同将于{{to_over_time[0].end_time}}到期。</span>
        </van-col>
        <van-col span="1">
        </van-col>
      </van-row>
    </van-cell-group>
    <van-col class="index-service-detail" >
      <van-row>
        <span  class="service-style" @click="toInvoice">
          <van-cell class="invoice-detail">
            <van-col span="18" class="invoice-desc">
              <span class="font-service-color">开票服务</span>
            </van-col>
            <van-col span="24" class="invoice-desc">
              <span class="font-service-desc"> 代开发票，个性注册</span>
            </van-col>
             <van-col span="10">
              <img  src="/assets/invoice_service.png" style="width: 1.64rem;height: 1.64rem;padding-left:2rem;padding-top: 0.1rem" />
            </van-col>
          </van-cell>
        </span>
        <span style="width: 0.2rem"></span>
        <span class="service-style" @click="toPcar">
           <van-cell class="invoice-detail">
            <van-col span="18" class="invoice-desc">
              <span class="font-service-color">停车服务</span>
            </van-col>
            <van-col span="24" class="invoice-desc">
              <span class="font-service-desc"> 车位租赁信息</span>
            </van-col>
            <van-col span="10">
              <img  src="/assets/p.png" style="width: 1.64rem;height: 1.64rem;padding-left:2rem;padding-top: 0.1rem" />
            </van-col>
          </van-cell>
        </span>
      </van-row>
    </van-col>
<!--    <div style="height: 0.45rem"></div>-->
    <van-cell-group class="index-swipe-detail" indicator-color="white">
      <van-swipe :autoplay="3000">
        <van-swipe-item v-for="(image, index) in images" :key="index" style="min-height: 14vh;height: auto">
          <img  @click="toPoster(image.url)" :src="image.img_url" style="width: 100%;height: 100%" />
        </van-swipe-item>
      </van-swipe>
    </van-cell-group>
<!--      <div style="height: 0.32rem;display: block"></div>-->
    <van-cell-group class="index-detail" @click="otherService">
      <van-cell class="invoice-detail">
        <van-row>
          <van-col span="16" class="index-title">市场其他服务</van-col>
        </van-row>
      </van-cell>
      <van-cell>
        <van-col span="24" class="index-other">
          <van-row style="margin-left: -10px">
            <van-col :span="6" class="index-other">
              <img   src="/assets/agency.png" style="width: 40px;height: 40px" /><br>
              <span class="font-color" >代办代理</span>
            </van-col>
            <van-col :span="6" class="index-other">
              <img   src="/assets/goods_service.png" style="width: 40px;height: 40px;" /><br>
              <span class="font-color">货物服务</span>
            </van-col>
            <van-col :span="6" class="index-other">
              <img   src="/assets/transport.png" style="width: 40px;;height: 40px;" /><br>
              <span class="font-color">运输配送</span>
            </van-col>
            <van-col :span="6" class="index-other">
              <img   src="/assets/preservation.png" style="width: 40px;;height: 40px;" /><br>
              <span class="font-color">仓储保鲜</span>
            </van-col>
          </van-row>
        </van-col>
      </van-cell>
    </van-cell-group>
    <div style="height: 2rem"></div>
  </div>
</template>

<script>
import {reactive,  toRefs } from "vue";
import {getMerchantDetail} from "@/api/merchant";
import {getContractInfo} from "@/api/contract";
import {useRouter} from "vue-router";
// import {Toast} from "vant";
export default {
  setup() {
    const router = useRouter();
    const state = reactive({
      contract_number:0,
      total_contract_amount:0.00,
      to_pay_data:[],
      to_over_time:[],
      images: [
        {
          img_url: '/assets/swipe1.png',
          url: 'https://mp.weixin.qq.com/s/jZqFyLhdqkUx3wtbhKjCsQ'
        },
        {
          img_url: '/assets/swipe2.png',
          url: 'https://mp.weixin.qq.com/s/Wri_Xr8jSP3yXz5EHdnUIQ'
        },
      ],

    });
    //跳转到合同
    const toContract = async() =>{
      router.push('/contract/list');
    }
    //跳转到发票
    const toInvoice = async() =>{
      router.push('/tax/list');
    }
    //跳转到停车服务
    const toPcar = async() =>{
      router.push('/parking_list/list');
    }
    //轮播图跳转
    const toPoster = async(value) =>{
      location.href = value;
    }
    //跳转到其他服务
    const otherService = async() =>{
      location.href ="https://www.lwfruit.com/";
    }
    return {
      ...toRefs(state),
      toContract,
      toInvoice,
      toPcar,
      otherService,
      toPoster,

    };
  },
  created() {
    let open_id = this.$route.query.open_id;
    // localStorage.removeItem('open_id');
    if(open_id){
      localStorage.setItem('open_id',open_id)
    }
    // 刷新 merchant info
    getMerchantDetail().then(function (res){
      if(res.Code===200){
        const info = res.Data;
        //持久化
        localStorage.setItem('user',JSON.stringify(info))
      }
    }).catch(function (err){
      console.info(err)
    });
    //商户合同基本信息
    this.contractInfo();
  },
  methods: {
    contractInfo(){
      let _this = this
      // 异步数据
      getContractInfo().then(function (res){
        if(res.Code===200){
          _this.contract_number = res.Data.contract_number;
          _this.total_contract_amount = res.Data.total_contract_amount;
          _this.to_pay_data = res.Data.to_pay_data;
          _this.to_over_time = res.Data.to_over_time;
        }
      }).catch(function (err){
        console.info(err)
      })
    },
  },
  mounted() {
    let open_id = localStorage.getItem('open_id');
    let user = JSON.parse(localStorage.getItem('user'));
    if(!open_id){
      window.location.href = '/wechat/user?state='+user.id;
    }
  }
};
</script>
<style>
.van-swipe__indicator--active{
   background-color: white;
  opacity: 1;
}
.index{
  height: 100vh;
}
.index-detail{
  width: 94%;
  margin:0.32rem auto;
  border-radius: 5px;
  /*background-color: #ffffff;*/
}
.index-contract-detail{
  width: 94%;
  margin:12px auto;
  border-radius: 5px;

}
.notice-font-color{
  color:#666666;
  padding-left: 7px;
}
.index-service-detail{
  width: 94%;
  margin:12px auto;
}
.index-swipe-detail{
  width: 94%;
  margin:0.32rem auto 0 auto;
  border-radius: 6px;
  height: auto;
  min-height: 13vh;
  display: block;
}
.index-swipe-detail .van-swipe__indicators{
    bottom:0.4rem;
}
.index-title{
  width: 1.86667rem;
  height: 0.6333rem;
  font-size: 0.48rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
}
.index-contract-detail .van-cell::after{
  border-bottom:none;
}
.index-detail .van-cell::after{
  border-bottom:none;
}
.font-contract-color{
  width: 1.36rem;
  height: 0.34667rem;
  font-size: 0.34667rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #333333;
}
.index-contract-number{
  height: .76667rem;
  font-size: .34667rem;
  font-weight: 400;
  color: #666;
}
.invoice-contract-desc{
  width: 30px;
  height: 20px;
  font-size: 26px;
  font-family: PingFang SC;
  font-weight: 600;
  color: #333333;
  margin: 0.2rem auto;

}
.font-service-color{
  width: 70px;
  height: 17px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
}
.font-service-desc{
  width: 2.90667rem;
  height: 0.32rem;
  font-size: 0.32rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #999999;
}
.font-color{
  width: 1.38667rem;
  height: 0.34667rem;
  font-size: 0.34667rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #333333;
}
.invoice-desc{
  height:.66667rem;
  font-size:.34667rem;
  font-weight:400;
  color:#666
}
.index-other{
  text-align: center;
}
/*icon*/
.active_tab img {
  width: 26px;
  height: 26px;
}

.van-tabbar-item--active {
  color: #e10f02;
}

.service-style{
  flex: 0 0 48.86666667%;
  height:3.35rem;
  background-color: #fff;
  border-radius: 5px;
}
.service-style .van-cell{
  position: relative;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  padding: 0.26667rem 0.42667rem;
  overflow: hidden;
  color: #07c160;
  font-size: 0.37333rem;
  line-height: 0.64rem;
  background: none;
}



</style>
